import React, { useState, useRef, useEffect } from "react"
import ReactHtmlParser from "react-html-parser"

import { LightBox } from "../components/LightBox"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { siteMetaCtx } from "../store/siteMeta/context"
import { RoomFullPage } from "../components/RoomPage/RoomFullPage"
import { Breadcrumb } from "../ui/Breadcumb"
import { Section } from "../ui/Section"

const FindUsTemplate = ({ pageContext, uri, ...props }) => {
  const { basic_info, full_page, content, ...restData } = pageContext.acf
  const [previewState, setPreviewState] = useState({ isOpen: false, index: 0 })

  const imagesRef = useRef([])
  const images = imagesRef.current

  const handleImgClick = src => {
    const imgIdx = images.findIndex(imageSrc => imageSrc === src)

    setPreviewState({ isOpen: true, index: imgIdx })
  }

  const handlePreviewClose = () => {
    setPreviewState(prev => ({ ...prev, isOpen: false }))
  }

  useEffect(() => {
    imagesRef.current = []
  })

  return (
    <siteMetaCtx.Provider value={{ uri }}>
      <Layout>
        <SEO
          lang="zh-TW"
          title={basic_info.seo_title}
          description={basic_info.seo_description}
        ></SEO>
        <RoomFullPage data={full_page} />
        <section className="position-relative">
          <div className="contact-us-body">
            <Breadcrumb
              paths={basic_info.breadcumb.map(path => ({
                text: path.name,
                link_url: path.link_name,
              }))}
            />
            <LightBox
              images={images}
              isOpen={previewState.isOpen}
              viewIndex={previewState.index}
              onClose={handlePreviewClose}
            />
            {content.sections.map((section, idx) => (
              <div className="wrapper" key={idx}>
                <Section title={section.title} subtitle={section.subtitle}>
                  <div className="row my-4">
                    <div className="col-24 html-parser-block">
                      {ReactHtmlParser(section.content, {
                        transform: node => {
                          if (node.name === "img") {
                            imagesRef.current.push(node.attribs.src)
                            const {
                              srcset,
                              class: _class,
                              ...attrs
                            } = node.attribs
                            return (
                              <img
                                {...attrs}
                                className={_class}
                                srcSet={srcset}
                                onClick={() => handleImgClick(node.attribs.src)}
                                key={Math.random()}
                              />
                            )
                          }
                        },
                      })}
                    </div>
                  </div>
                </Section>
              </div>
            ))}
          </div>
        </section>
      </Layout>
    </siteMetaCtx.Provider>
  )
}

export default FindUsTemplate
