import React from "react"

export const Section = props => {
  return (
    <div className="section">
      <div className="mb-4">
        <span className="title">{props.title}</span>
        {props.subtitle ? <span className="mx-2 title">/</span> : ""}
        <span className="subtitle">{props.subtitle ? props.subtitle : ""}</span>
      </div>
      <div className="divider"></div>
      <div>{props.children}</div>
    </div>
  )
}
