import React, { useState, useEffect } from "react"

import { default as ReactImageLightbox } from "react-image-lightbox"
import "react-image-lightbox/style.css"

export const LightBox = ({ images, isOpen, viewIndex, onClose }) => {
  const [previewState, setPreviewState] = useState({ isOpen: false, index: 0 })

  useEffect(() => {
    setPreviewState(prev => ({
      ...prev,
      isOpen: isOpen,
      index: viewIndex,
    }))
  }, [isOpen, viewIndex])

  return (
    <>
      {previewState.isOpen && (
        <ReactImageLightbox
          mainSrc={images[previewState.index]}
          nextSrc={images[(previewState.index + 1) % images.length]}
          prevSrc={
            images[(previewState.index + images.length - 1) % images.length]
          }
          onCloseRequest={() => {
            setPreviewState(prev => ({ ...prev, isOpen: false }))
            onClose()
          }}
          onMovePrevRequest={() =>
            setPreviewState(prev => ({
              ...prev,
              index: (previewState.index + images.length - 1) % images.length,
            }))
          }
          onMoveNextRequest={() =>
            setPreviewState(prev => ({
              ...prev,
              index: (previewState.index + 1) % images.length,
            }))
          }
        />
      )}
    </>
  )
}
